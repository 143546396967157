import React from 'react';
import { useState, useRef, useEffect } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";
import iconAddImageSvg from '../../assets/images/add_photo.svg';
import imageSelectDone from '../../assets/images/image_select_done.svg';
import imageDownload from '../../assets/images/icon_download.svg';
import CallApi from '../../network/CallApi';
import Spinner from 'react-bootstrap/Spinner';


const Home = () => {
    let isSelectFace = true;
    const inputOriginalImageRef = useRef(null);
    const [faceImage, setFaceImage] = useState(null);
    const [originalImage, setOriginalImage] = useState(null);
    const [resultImage, setResultImage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [originalBase64, setOriginalBase64] = useState(null);
    const [faceBase64, setFaceBase64] = useState(null);

    const getKeyReface = async () => {
        setResultImage(null)
        if (showLoading == false) {
            if ((faceImage != null && originalImage != null)) {
                setShowLoading(true)
                try {
                    const postData = { original_image: originalBase64, face_image: faceBase64, apikey: 'swapfree' };
                    const postResponse = await CallApi.postFormData('https://swaface.com/api/swapface/', postData);
                    const jsonObject = JSON.parse(postResponse);
                    console.log("=================>>>postResponse: ", typeof (jsonObject));
                    console.log("=================>>>code: ", jsonObject.code);
                    if (jsonObject.code === 200) {
                        setResultImage(jsonObject.data);
                        setShowLoading(false)
                        console.log("=================>>>postResponse: ", postResponse);
                    } else if (jsonObject.code === 406) {
                        setShowLoading(false)
                        alert("Bạn đã sử dụng hết số lượt chuyển đổi!")
                    } else {
                        setShowLoading(false)
                        alert("Có lỗi xảy ra, Vui lòng thử lại")
                    }
                } catch (error) {
                    setShowLoading(false)
                    alert("Có lỗi xảy ra, Vui lòng thử lại")
                }
            } else {
                alert("Please select the image")
            }
        }
    }

    const downloadImage = () => {
        if (resultImage) {
            const link = document.createElement("a");
            link.href = "data:image/png;base64," + resultImage;
            link.download = "refaced_image.png"; // Tên file tải xuống
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedImage = e.target.files[0];
            console.log("============selectedImage name: ", selectedImage.name);
            const base64Image = await convertFileToBase64(selectedImage);
            if (isSelectFace) {
                setFaceImage(selectedImage);
                setFaceBase64(base64Image);
            } else {
                setOriginalImage(selectedImage);
                setOriginalBase64(base64Image);
            }
        } else {
            console.log("No file selected.");
            // Thêm các xử lý khác nếu cần
        }
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    function formatImageName(imageName) {
        if (imageName.length <= 20) {
            return imageName;
        } else {
            const firstPart = imageName.substring(0, 14);
            const extensionIndex = imageName.lastIndexOf('.');
            const extension = imageName.substring(extensionIndex);
            return `${firstPart}...${extension}`;
        }
    }

    return (
        <div className="container_home">
            <div className="top_home">

            </div>

            <div className="body_home">

                <text class='text_info_account'>Face Swap Online Free</text>
                <text class='text_info_tool'>Powerful online tool to seamlessly swap one face with another.</text>


                <input class='input_hide' ref={inputOriginalImageRef} type="file" accept="image/*" onChange={(e) => handleImageChange(e)} />
                <div class='main_select_image'>
                    <div
                        onClick={() => {
                            if (showLoading == false) {
                                isSelectFace = true
                                inputOriginalImageRef.current.click()
                            }
                        }}
                        class='main_select_face'>
                        <img
                            class={'image_select'}
                            src={faceImage ? imageSelectDone : iconAddImageSvg} />

                        <text class='text_title_select_image'>{faceImage ? formatImageName(faceImage.name) : 'Select face image'}</text>
                    </div>


                    <div onClick={() => {
                        if (showLoading == false) {
                            isSelectFace = false
                            inputOriginalImageRef.current.click()
                        }
                    }}
                        class='main_select_face'>
                        <img
                            class={'image_select'}
                            src={originalImage ? imageSelectDone : iconAddImageSvg} />

                        <text class='text_title_select_image'>{originalImage ? formatImageName(originalImage.name) : 'Select original image'}</text>
                    </div>


                    <div className={`main_button`}>
                        <div
                            className={`button_reface ${resultImage ? "half-width" : "full-width"}`}
                            onClick={() => getKeyReface()}
                            style={{ opacity: faceImage && originalImage ? 1 : 0.8 }}
                        >
                            <Spinner
                                style={{ display: showLoading ? '' : 'none' }}
                                size="sm"
                                animation="border"
                                role="status"
                                variant="light"
                            >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <span className="text_title_swap_image">Start reface</span>
                        </div>

                        {resultImage && (
                            <div className="button_download show" onClick={() => downloadImage()}>
                                <img src={imageDownload} />
                                <span className="text_title_download">Download</span>
                            </div>
                        )}
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Home;