import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    const showNext = (link) => {
        navigate(link);
    }

    return (
        <div class="main_header">
            <text onClick={() => showNext('/')} class='title_web'>Swaface</text>
            <div class='right_header'>
                {/* <text class='text_menu_tab'>Api doc</text>
                <text class='text_menu_tab'>Contact</text>
                <text onClick={() => showNext('/login')} class='text_menu_tap_login'>Login</text> */}
            </div>
        </div>
    )
}

export default Header;